<template>
  <div>


<!--<b-row>-->
<!--  <b-col cols="10">-->
<!--    <div class="">-->
<!--      <div class="d-md-flex flex-row mb-2  text-md-left text-center">-->

<!--        <b-button-->
<!--            @click="$router.back()"-->
<!--            class="shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white"-->
<!--            variant="white">-->
<!--          <feather-icon icon="ChevronLeftIcon" size="20"/>-->
<!--        </b-button>-->
<!--          <div class="mt-1 ml-1">-->
<!--            <h3>Documents</h3>-->
<!--          </div>-->

<!--      </div>-->
<!--    </div>-->
<!--  </b-col>-->
<!--  <b-col cols="2">-->
<!--    <b-button-->
<!--        @click="openFileUploadModel()"-->
<!--        class="shadow-sm   align-self-baseline float-right"-->
<!--        variant="primary"-->
<!--        size="small">-->
<!--      &lt;!&ndash;              <feather-icon icon="PlusIcon" size="22"/>&ndash;&gt;-->
<!--      <span >+ Add Document</span>-->
<!--    </b-button>-->
<!--  </b-col>-->
<!--</b-row>-->
    <b-card no-body>
      <div>
        <b-row>
          <b-col class="" cols="3"  >
            <b-form-group>
              <b-input-group class="input-group-merge ml-1 mt-1">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                    id="icons-search"
                    placeholder="Search"
                    v-model="filters['search']"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class=" " cols="9" >
          <b-button
            @click="openFileUploadModel()"
            size="m"
            class="shadow-sm   align-self-baseline float-right button-size"
            variant="primary"
            >
<!--                 <feather-icon icon="PlusIcon" size="22"/>-->
            <span >+ Add Document</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="p-1">

          <b-col cols="12">
            <b-overlay
                :show="tableLoading"
                rounded="sm"
            >

              <b-table
                  ref="table"
                  :current-page="currentPage"
                  :per-page="pagination.perPage"
                  :fields="fields"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :items="getList"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  hover

                  class="mobile_table_css"
                  responsive
              >

                <template v-slot:head(date)="data">
                  <span v-b-tooltip.hover.top="'Sort'">{{ data.label }}</span>
                </template>

                <template
                    slot="table-row"
                    slot-scope="data"
                ></template>

                >

                <!-- Name $ Avatar -->

                <template #cell(name)="data"

                >
                  <b-col>
                    <b-media no-body >
                      <!--                    <b-form-checkbox-->
                      <!--                        class="align-self-center mr-1"-->
                      <!--                        v-model="checked"-->
                      <!--                        :indeterminate.sync="indeterminate"-->
                      <!--                    >-->
                      <!--                    </b-form-checkbox>-->
                      <b-img
                          :src="require('@/assets/images/filetypes/pdf.png')"
                          alt="browser img"
                          class="mr-1 "
                          height="30"
                      />
                      <h6 class="align-self-center my-auto font-weight-bolder">
                        {{data.item.name}}
                      </h6>
                    </b-media>
                  </b-col>
                </template>

                <!-- Paid Unpaid Button -->

                <template #cell(action)="data">
                  <b-button
                      v-b-tooltip.hover.top="'Download'"
                      size="23"
                      variant="outline-white"
                      class="btn-icon bg-white text-primary m-1 shadow-sm"
                      @click="downloadFile(data.item)"
                  >
                    <feather-icon icon="DownloadIcon" size="18" />
                  </b-button>



                  <!--                <b-button-->
                  <!--                    size="23"-->
                  <!--                    variant="outline-white"-->
                  <!--                    class="btn-icon bg-white text-gray ml-1"-->
                  <!--                    @click=""-->
                  <!--                >-->
                  <!--                  <feather-icon icon="PrinterIcon" size="18" variant="outline-danger"/>-->
                  <!--                </b-button>-->
                  <b-button
                      v-b-tooltip.hover.top="'Delete'"
                      size="23"
                      variant="outline-white"
                      class=" bg-white text-danger m-1 shadow-sm"
                      @click="deleteFile(data.item.id)"
                  >
                    <feather-icon icon="TrashIcon" size="18" variant="outline-danger"/>
                  </b-button>


                  <!--                <b-dropdown-->
                  <!--                    variant="link"-->
                  <!--                    toggle-class="text-decoration-none"-->
                  <!--                    no-caret-->
                  <!--                >-->
                  <!--                  <template v-slot:button-content>-->
                  <!--                    <feather-icon-->
                  <!--                        icon="MoreVerticalIcon"-->
                  <!--                        size="16"-->
                  <!--                        class="text-body align-middle mr-25"-->
                  <!--                    />-->
                  <!--                  </template>-->
                  <!--                  <b-dropdown-item>-->
                  <!--&lt;!&ndash;                    <a  target="blank" :href="`http://hyre-be-v2.test/api/mp/salaried-staff/files/${data.item.id}/download?auth_practice_id=1`">&ndash;&gt;-->
                  <!--                      <b-button variant="outline-white"  @click="downloadFile(data.item)" >-->
                  <!--                        <feather-icon icon="DownloadIcon" class="mr-50"-->
                  <!--                        />-->
                  <!--                        <span>Download</span>-->
                  <!--                      </b-button>-->
                  <!--&lt;!&ndash;                    </a>&ndash;&gt;-->
                  <!--                  </b-dropdown-item>-->
                  <!--                  <b-dropdown-item>-->
                  <!--                    <b-button variant="outline-white" @click="" >-->
                  <!--                      <feather-icon icon="PrinterIcon" class="mr-50"  />-->
                  <!--                      <span>Printr</span>-->
                  <!--                    </b-button>-->
                  <!--                  </b-dropdown-item>-->
                  <!--                  <b-dropdown-item>-->
                  <!--                    <b-button variant="outline-white" @click="deleteFile(data.item.id)" >-->
                  <!--                      <feather-icon icon="TrashIcon" class="mr-50"  />-->
                  <!--                      <span>Delete</span>-->
                  <!--                    </b-button>-->
                  <!--                  </b-dropdown-item>-->
                  <!--                </b-dropdown>-->

                </template>
              </b-table>
            </b-overlay>
          </b-col>
          <b-col cols="12" v-if="noDataTable === 0" class="text-center">
            <span >No data for preview</span>
          </b-col>


          <!--             Pagination-->

          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
            <span class="text-muted">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
          </b-col>

          <!-- Pagination -->

          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">
            <b-pagination
                v-model="currentPage"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                class="mb-0 mt-1 mt-sm-0 "
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>

            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
        id="modal-center"
        v-model="showFileUploadModal"
        no-close-on-backdrop
        centered
        hide-footer
        size="lg"
        title="Document Uploader"
    >
      <b-overlay
          :show="modalLoading"
          rounded="sm"
      >
        <b-row class="mx-1">
          <b-col cols="12" class="mt-2" >
            <div style="width: 100px ; height: 100px" class="m-auto">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<rect y="0" style="fill:#32BEA6;" width="512" height="512"/>
                <g>
	<polygon style="fill:#FFFFFF;" points="182.76,229.728 232.704,178.808 232.704,301.688 279.296,301.688 279.296,178.808
		329.24,229.728 362.488,197.288 256.008,88.72 149.512,197.288 	"/>
                  <polygon style="fill:#FFFFFF;" points="395.528,421.864 116.472,421.864 116.472,301.088 132.472,301.088 132.472,405.864
		379.528,405.864 379.528,301.088 395.528,301.088 	"/>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
</svg>
            </div>
          </b-col>
          <b-col cols="12" class="mt-3">
            <b-overlay
                :show="buttonLoading"
                rounded="sm"
            >

              <v-select
                  v-model="selected_file_type_id"
                  :options="fileTypeOption"
                  :reduce="fileTypeOption => fileTypeOption.id"
                  label="name"
                  placeholder="Select Document Type"
                  value="id"
                  class="mb-2"
              />

            <b-form-file
                v-model="uploadedFile"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                ref="image_ref"
            />
            </b-overlay>
          </b-col>
          <b-col cols="12" class="mt-5">
            <div class="float-right">
              <b-button
                  class="mr-2"
                  variant="primary"
                  :disabled="isActive"
                  @click="uploadFile()">
<!--                <b-overlay-->
<!--                    :show="buttonLoading"-->
<!--                    rounded="sm"-->
<!--                >-->
                  Upload
<!--                </b-overlay>-->
              </b-button>

<!--              <b-button variant="warning" @click="showFileUploadModal = false">Cancel</b-button>-->
              <b-button variant="warning" @click="isActive = true , showFileUploadModal = false">Cancel</b-button>
              <!--            <b-button @click="downloadFile()">Download</b-button>-->
              <!--              <b-button @click="getList()">List</b-button>-->
            </div>
          </b-col>
        </b-row>
      </b-overlay>

    </b-modal>
  </div>

</template>

<script>
import {
  BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow,
  BFormCheckbox,
  BCardBody,
  BCardHeader,
  BImg,
  BMedia,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
  BFormFile,
  BOverlay,
  VBTooltip, VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {baseApiUrl} from '@/constants/config'
import fileUploader from '../../my-documents/fileUploader'
import profile from '@/apis/modules/profile'
import practice from '@/apis/modules/practice'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import axios from 'axios'
import Ripple from "vue-ripple-directive";
import leave from '@/apis/modules/leave'
import Filter from '@/mixins/FilterMixin'
import settings from '@/apis/modules/settings'



export default {
  name: 'documents',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BCardBody,
    BCardHeader,
    BFormCheckbox,
    BImg,
    BMedia,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,

    VBTooltip
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [MomentMixin, Filter],

  data() {
    return {
      fileTypeOption:[],
      selected_file_type_id:'',
      buttonLoading:false,
      tableLoading:false,
      noDataTable:'',
      uploadedFile: null,
      showFileUploadModal:false,
      modalLoading:false,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filters:{},
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'name',
          label: 'DOCUMENT NAME',
          sortable: false
        },
        {
          key: 'date',
          label: 'DATE UPLOADED',
          sortable: true
        },
        {
          key: 'uploaded_by',
          label: 'UPLOADED BY',
          sortable: false
        },

        {
          key: 'action',
          label: '',

        },


      ],
      items: [],
      isActive: true,

    }
  },

  watch: {
    uploadedFile() {
      this.isActive = false
    }
  },
  methods:{
    async getFileType () {
      try {
        this.tableLoading = true

        const Response = await settings.getFileType()
        this.fileTypeOption = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
        }))

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },

    async getList(){
      try {
        this.tableLoading = true
        const Response =  await  practice.getImagesList(this.filterQuery)
        let dataArray =  Response.data.data.map((x) => ({
          id: x.id,
          name:x.name,
          date:this.momentFormat(x.created_at,'dddd YYYY-MM-DD'),
          uploaded_by:x.updated_by.first_name +' '+ x.updated_by.last_name
        }))


        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false

        return dataArray

      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading =false

      }

    },
    async downloadFile(item){
      try {
        this.tableLoading = true;
        // const response =


        // const response = await practice.downloadImage(item.id)
        axios
            .get(`https://hyre.locumboxapp.com/api/mp/practice-admin/files/${item.id}/download?auth_practice_id=${sessionStorage.getItem('practice_id')}`, {
              // headers{
              // }
              responseType: 'arraybuffer',
              withCredentials:true
            })
            .then(response => {

              let blob = new Blob([response.data], { type: 'application/pdf' }),
                  // url = window.URL.createObjectURL(blob)
                  // let blob = new Blob([response.data], { type: 'application/pdf' })
                  link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = item.name
              link.click()
              // setTimeout(() => window.URL.revokeObjectURL(link), 100)

              // window.open(url)



              // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
              // let link = document.createElement('a')
              // link.href = window.URL.createObjectURL(blob)
              // link.download = item.name
              // link.click()
            })
        //

        this.showSuccessMessage('File Downloaded Successfully')
        this.$refs.table.refresh()
      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async deleteFile(id){
      try {
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure you want to delete this document?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(async result => {
          if (result.value) {
            await practice.deleteToFile(id)
            this.$refs.table.refresh()
            this.tableLoading = false
          }
        })
        // this.tableLoading = false
      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
      // try {
      //   this.tableLoading = true
      //   await practice.deleteToFile(id)
      //   this.tableLoading = false
      //   this.$refs.table.refresh()
      // }catch (error){
      //   this.convertAndNotifyError(error)
      //   this.tableLoading = false
      // }
    },
    async uploadFile(){
      try {
        this.buttonLoading = true
        fileUploader.store(this.uploadedFile, {
          progress: progress => {
            this.uploadProgress = Math.round(progress * 100)
          },
          'visibility': 'public-read',
          // baseURL: 'http://hyre-be-v2.test/api/mp',
          baseURL: `${baseApiUrl}`,

          params:{
            'auth_practice_id':`${sessionStorage.getItem('practice_id')}`
          }
        }).then(async response => {
          const key = response.key
          await this.setImageKeyToApi(key)
          this.isActive = true
        })

      }catch (error) {
        this.convertAndNotifyError(error)


      }


    },
    async setImageKeyToApi (key) {

      (await practice.getImage({key: key, name:this.uploadedFile.name,file_type_id:this.selected_file_type_id,}  ))
      // this.items[_.findIndex(this.items, {id:this.item_id})].item_image_key = key
      this.showSuccessMessage('Document Updated Successfully')
      this.buttonLoading = false
      this.$refs.table.refresh()


      this.showFileUploadModal = false


      // setTimeout (() => {
      //   const new_src = `${this.api_url}/items/${this.item_id}/image?${Date.now()}`
      //   var image = document.getElementById(`${this.item_id}_img`);
      //   image.src = new_src
      //   this.item_id = null
      //   this.popupActiveImage = false
      // },500)
    },

    openFileUploadModel(){
      try {
        this.showFileUploadModal = true

      }catch (error){
        this.convertAndNotifyError(error)
      }
    },
    filterQueryUpdate () {
      this.$refs.table.refresh()
    },
  },

  mounted () {
    this.getFileType()
  }
}
</script>

<style scoped>

</style>

<styl lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</styl>
